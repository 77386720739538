import { useFormikContext, type FormikTouched } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslations, TranslationKeyPrefix } from './useTranslations'

type FormError = {
  message: string
  count: number
}

export const useFormikErrorCount = <FormFieldsDefinition>(): FormError => {
  const { getTranslationLiteral: tl } = useTranslations(TranslationKeyPrefix.AccountCreation) // common translation namespace
  const { errors, touched, isSubmitting } = useFormikContext<FormFieldsDefinition>()
  const [error, setError] = useState<FormError>({ message: '', count: 0 })

  useEffect(() => {
    // based on the touched fields in the form, count the number of errors
    const _errorCount = Object.keys(errors).filter(
      (key) => touched[key as keyof FormikTouched<FormFieldsDefinition>]
    ).length

    if (!isSubmitting && _errorCount > 0) {
      const tKey = _errorCount === 1 ? 'common.form_error' : 'common.form_errors'
      const text = tl(tKey).replace('{numberOfErrors}', _errorCount.toString())

      setError({ message: text, count: _errorCount })
    } else {
      setError({ message: '', count: 0 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting])

  return error
}
