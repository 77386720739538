import Link from 'next/link'
import { TranslationKeyPrefix, useTranslations } from 'hooks/useTranslations'
import styles from './index.module.scss'
import classNames from 'classnames'
import { isNavLinkActive } from 'utils/urls'
import { useRouter } from 'next/router'
import { Links } from 'configuration/routes'

interface NavigationProps {
  links: Links
}

export function Navigation({ links }: NavigationProps) {
  const { getTranslationLiteral: tl } = useTranslations(TranslationKeyPrefix.AccountCreation)
  const router = useRouter()

  return (
    <nav className={styles.nav}>
      <ul className={classNames('px-3 mt-6 d-flex justify-content-center', styles.nav__items)}>
        {links.map(({ url, tKey }, index) => (
          <li
            key={url}
            className={classNames('font-style-medium d-flex', styles.item)}
            aria-current={isNavLinkActive(router.pathname, url) ? 'page' : undefined}
          >
            <Link
              shallow
              passHref
              href={url}
              className={classNames({
                [styles.active]: isNavLinkActive(router.pathname, url),
              })}
              data-testid={`nav-link-${index}`}
            >
              {tl(tKey)}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}
