import React, {
  MouseEvent,
  KeyboardEvent,
  ReactElement,
  useRef,
  useState,
  forwardRef,
  Ref,
  AriaAttributes,
  ReactNode,
} from 'react'
import styles from './index.module.scss'
import { TranslationKeyPrefix, useTranslations } from '../../../hooks/useTranslations'
import { Icon } from 'components/Icon'
import classNames from 'classnames'

export interface IInputProps {
  assistiveText?: string
  validText?: string
  autoFocus?: boolean
  touched: boolean
  label: string
  type: 'text' | 'password' | 'email'
  name: string
  value: string
  error?: string
  onChange: any
  onBlur?: any
  disabled?: boolean
  classname?: string
  passwordRecommendation?: ReactElement
  emailExistsErrorLabel?: JSX.Element | ReactNode[] | string
  maxLength?: number
  autoComplete?: string
  optional?: boolean
  onEnterPressed?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onClearInput?: () => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  role?: string
  ariaAutocomplete?: AriaAttributes['aria-autocomplete']
  ariaControls?: AriaAttributes['aria-controls']
  ariaActiveDescendant?: AriaAttributes['aria-activedescendant']
  ariaExpanded?: AriaAttributes['aria-expanded']
}

export const Input = React.memo(
  forwardRef(
    (
      {
        assistiveText,
        validText,
        disabled = false,
        label,
        type,
        name,
        touched,
        value,
        error,
        onChange,
        onBlur,
        classname = '',
        passwordRecommendation,
        emailExistsErrorLabel,
        maxLength,
        autoComplete,
        optional = false,
        onEnterPressed,
        onClearInput,
        onKeyDown,
        role,
        ariaAutocomplete,
        ariaControls,
        ariaActiveDescendant,
        ariaExpanded,
      }: IInputProps,
      ref: Ref<HTMLInputElement>
    ): ReactElement => {
      const [isPassword] = useState(type === 'password')
      const [isPasswordVisible, setIsPasswordVisible] = useState(false)
      const [showPasswordToggle, setShowPasswordToggle] = useState(false)

      const inputRef = ref || useRef<HTMLInputElement>(null) // eslint-disable-line react-hooks/rules-of-hooks

      const ariaDescribedBy = (): string => {
        if (assistiveText && !error) {
          return `${name}-assitiveText`
        }
        if ((!!error && touched) || !!emailExistsErrorLabel) {
          return `${name}-error`
        }
        if (validText && !error) {
          return `${name}-valid`
        }
        return ''
      }

      const { getTranslationLiteral: tl } = useTranslations(TranslationKeyPrefix.Input)

      const renderBody = (): ReactElement => {
        const input = (
          <>
            <input
              ref={inputRef}
              data-testid={`${name}-input`}
              className={classNames(styles.input, 'chr-action-bold', {
                [styles.error]: type === 'password' && !isPasswordVisible,
                [styles.password__padding]: isPassword || onClearInput,
                [styles.disabled]: disabled,
              })}
              onChange={onChange}
              onBlur={(e) => {
                if (isPassword && setIsPasswordVisible) {
                  setIsPasswordVisible(false)
                }
                onBlur?.(e)
              }}
              onFocus={() => {
                if (!showPasswordToggle && isPassword) setShowPasswordToggle(true)
              }}
              name={name}
              id={`${name}-input`}
              type={isPassword ? (isPasswordVisible ? 'text' : type) : type}
              value={value}
              maxLength={maxLength || 30}
              disabled={disabled}
              autoComplete={autoComplete}
              onInput={(e) => {
                // Prevents entering more characters than allowed using an Android keyboard.
                // https://stackoverflow.com/questions/24443007/maxlength-attribute-of-input-in-html-does-not-work-on-htc-one-m7#answer-56186285
                const { maxLength } = e.currentTarget
                if (e.currentTarget.value.length > maxLength) {
                  e.currentTarget.value = e.currentTarget.value.slice(0, maxLength)
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                  e.preventDefault()

                  if (onEnterPressed) {
                    onEnterPressed(e)
                  }
                }
                onKeyDown?.(e)
              }}
              role={role}
              aria-describedby={ariaDescribedBy()}
              aria-autocomplete={ariaAutocomplete}
              aria-controls={ariaControls}
              aria-activedescendant={ariaActiveDescendant}
              aria-expanded={ariaExpanded}
            />
            <label
              data-testid={`${name}-label`}
              htmlFor={`${name}-input`}
              className={classNames(styles.label, {
                [styles.disabled]: disabled,
              })}
            >
              {label}
              {optional && <span className={styles.optional}>({tl('value.optional')})</span>}
            </label>
          </>
        )

        if (type === 'password') {
          return (
            <div className={styles.input__password}>
              {input}
              {showPasswordToggle && (
                <button
                  data-testid="password-show"
                  type="button"
                  onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault()
                    setIsPasswordVisible((prevState) => !prevState)
                  }}
                  onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                    if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                      e.preventDefault()
                      setIsPasswordVisible((prevState) => !prevState)
                    }
                  }}
                  className={styles.icon}
                  aria-pressed={isPasswordVisible}
                  aria-label={
                    !isPasswordVisible
                      ? tl('password.reveal_password')
                      : tl('password.hide_password')
                  }
                >
                  {!isPasswordVisible ? tl('password.show') : tl('password.hide')}
                </button>
              )}
            </div>
          )
        }

        if (onClearInput) {
          return (
            <div className={styles.input__clear}>
              {input}
              <div
                className={classNames(styles.input__icon, {
                  [styles.disabled]: disabled,
                })}
              >
                {value ? (
                  <button
                    type="button"
                    onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
                      e.preventDefault()
                      onClearInput()
                    }}
                    onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                      if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                        e.preventDefault()
                        onClearInput()
                      }
                    }}
                    className={styles.icon}
                    aria-label={tl('address.lookup.button.clear')}
                    disabled={disabled}
                    data-testid={`${name}-clear-button`}
                  >
                    <Icon icon="crossClear" variant="light" disabled={disabled} />
                  </button>
                ) : (
                  <div
                    className={classNames(styles.icon, {
                      [styles.disabled]: disabled,
                    })}
                    aria-hidden="true"
                  >
                    <Icon icon="search" variant="light" disabled={disabled} />
                  </div>
                )}
              </div>
            </div>
          )
        }

        return <div className={styles.input__password}>{input}</div>
      }

      return (
        <div
          className={classNames(styles.input__holder, classname, {
            [styles.error]: error && touched,
            [styles.disabled]: disabled,
          })}
        >
          {renderBody()}
          {assistiveText && !error && (
            <div
              data-testid={`${name}-assitiveText`}
              id={`${name}-assitiveText`}
              className={classNames(styles.text, {
                'chr-color-grey-alpha-40': disabled,
              })}
            >
              <p>{assistiveText}</p>
            </div>
          )}
          {((!!error && touched) || emailExistsErrorLabel) && (
            <div className={styles.err} id={`${name}-error`}>
              <div className={styles.err__icon}>
                <Icon icon="info" variant="red" />
              </div>
              <p data-testid={`${name}-error`} className={styles.err__text}>
                {error || emailExistsErrorLabel}
              </p>
            </div>
          )}
          {validText && !error && (
            <div className={styles.match} id={`${name}-valid`}>
              <div className={styles.match__holder}>
                <Icon icon="tick" variant="light" />
              </div>
              <p>{validText}</p>
            </div>
          )}
          {passwordRecommendation && showPasswordToggle && passwordRecommendation}
        </div>
      )
    }
  )
)

Input.displayName = 'Input'
