import { useState, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { logService } from 'utils/logService'
import { pageType } from 'configuration/analytics'

interface FormProps {
  details: pageType
}

export const FormSubmitErrorTracker = ({ details }: FormProps): null => {
  const { submitCount, isValid, isSubmitting, errors } = useFormikContext()
  const [lastHandled, setLastHandled] = useState(0)

  useEffect(() => {
    if (submitCount > lastHandled && !isValid && !isSubmitting) {
      logService.trackForm({
        type: 'error',
        details,
        attempt: submitCount,
        errors,
      })
      setLastHandled(submitCount)
    }
  }, [details, errors, isSubmitting, submitCount, isValid, lastHandled])
  return null
}
