import * as yup from 'yup'
import actions from 'actions/authentication'
import Head from 'next/head'
import SessionProvider from 'session'
import { Button } from 'components/Button'
import { environment } from 'configuration'
import { ErrorBanner } from 'components/Form/Error'
import { Form, Formik, useFormikContext } from 'formik'
import { FormSubmitErrorTracker } from 'components/Form/FormSubmitErrorTracker'
import { IAppState } from 'reducers'
import { Input } from 'components/Form/Input'
import { logService } from 'utils/logService'
import { Navigation } from 'components/Navigation'
import { navigationLinks } from 'configuration/routes'
import { PAGE_CONFIG } from 'configuration/analytics'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormikErrorCount } from 'hooks/useFormikErrorCount'
import { usePageTitle } from 'hooks/usePageTitle'
import {
  TranslationKeyPrefix,
  TranslationLiteralFunction,
  useTranslations,
} from 'hooks/useTranslations'

const { SIGN_IN } = PAGE_CONFIG

SessionProvider.clearEntryPoint()

interface IAuthFlowProps {
  language: string
}

interface SignInFormFields {
  email: string
  password: string
}

const validationRules = (tl: TranslationLiteralFunction) =>
  yup.object().shape({
    email: yup.string().email(tl('email.invalid')).required(tl('email.required')),
    password: yup.string().required(tl('password.required')),
  })

function InnerForm() {
  const { loading } = useSelector((state: IAppState) => state?.authentication)

  const {
    getTranslation: t,
    getTranslationLiteral: tl,
    getUrlByLinkKey: ublk,
    getTextByLinkKey: tblk,
  } = useTranslations(TranslationKeyPrefix.SignIn)

  const { handleSubmit, values, errors, touched, handleBlur, handleChange, submitForm } =
    useFormikContext<SignInFormFields>()

  const { message: error } = useFormikErrorCount<SignInFormFields>()

  // In order to hide the username (an email address) from the URL,
  // the Forgot password page is opened by submitting a generated form using the POST method.
  // https://christiestech.atlassian.net/browse/TW2-2896
  const openForgotPasswordPage = (email: string): void => {
    const postForm = document.createElement('form')
    postForm.method = 'POST'
    postForm.action = `${environment.dotComUrl}${ublk(tl('password.forgot'))}`
    postForm.target = '_blank'
    postForm.className = 'visually-hidden'
    postForm.ariaHidden = 'true'
    postForm.innerHTML = `<input type="hidden" name="email" value="${email}" />`
    document.body.appendChild(postForm)
    postForm.submit()
  }

  return (
    <Form name="signInForm">
      {error && (
        <div className="mt-4">
          <ErrorBanner message={error} />
        </div>
      )}
      <Input
        classname="mt-5"
        label={tl('email.label')}
        name="email"
        type="email"
        value={values['email']}
        error={errors['email'] || ''}
        touched={touched['email'] || false}
        onChange={(e: any) => {
          handleChange(e)
        }}
        onEnterPressed={submitForm}
        onBlur={handleBlur}
        maxLength={50}
      />

      <Input
        classname="mt-5"
        label={tl('password.label')}
        name="password"
        type="password"
        value={values['password']}
        error={errors['password'] || ''}
        touched={touched['password'] || false}
        onChange={(e: any) => {
          handleChange(e)
        }}
        onEnterPressed={submitForm}
        onBlur={handleBlur}
      />

      <div className="mt-2 text-align-right">
        <Button
          type="button"
          link
          buttonText={tblk(tl('password.forgot'))}
          className="chr-action-bold"
          onClick={() => openForgotPasswordPage(values.email)}
          testId="forgot-password"
        />
      </div>

      <div>
        <Button
          loading={loading}
          className="mt-6"
          type="button"
          onClick={handleSubmit}
          buttonText={tl('button.continue')}
        />
      </div>

      <hr className="mt-5" aria-hidden="true" />

      <div className="mt-5">
        <p>{t('create_account.prompt')}</p>
      </div>

      <FormSubmitErrorTracker details={PAGE_CONFIG.SIGN_IN} />
    </Form>
  )
}

const AuthFlow = (props: IAuthFlowProps): ReactElement => {
  const { language } = props
  const pageTitle = usePageTitle(TranslationKeyPrefix.SignIn)
  const dispatch = useDispatch()
  const error = useSelector((state: IAppState) => state?.authentication?.error)

  const { getTranslation: t, getTranslationLiteral: tl } = useTranslations(
    TranslationKeyPrefix.SignIn
  )

  useEffect(() => {
    logService.trackPage({
      language,
      details: SIGN_IN,
    })
  }, [language])

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <Navigation links={navigationLinks} />
      <div className="mt-7 mt-lg-11">
        <div
          className="container-fluid text-align-center"
          data-testid="form"
          data-analytics-region="signin-form"
        >
          <div className="row">
            <div className="col-12 col-md-4 offset-md-4">
              <h1 data-testid="login-header" className="chr-heading-l-serif">
                {t('header')}
              </h1>
              {error && (
                <div className="mt-4">
                  <ErrorBanner message={t(error)} />
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4 offset-md-4">
              <Formik
                validationSchema={validationRules(tl)}
                initialValues={{ password: '', email: '' }}
                onSubmit={(values) => {
                  dispatch(actions.resetErrorAction())
                  dispatch(
                    actions.userAuthenticateAction({
                      ...values,
                    })
                  )
                }}
              >
                <InnerForm />
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

AuthFlow.getInitialProps = () => {
  return {
    useMaximalHeader: true,
    useMaximalFooter: true,
    preloadLabelPrefixes: [
      TranslationKeyPrefix.SignIn,
      TranslationKeyPrefix.AccountCreation,
      TranslationKeyPrefix.Input,
    ],
  }
}

export default AuthFlow
